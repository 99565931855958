/* .App {
  text-align: center;
  }


@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&family=Roboto+Mono:ital@0;1&family=Roboto:wght@700&display=swap');


  /* Typography */

 /* h1{
      font-family:'Roboto Mono', monospace; 
      font-size: 2.5rem;
      color: darkslategray;
    }

 h2{
  font-family:'Roboto Mono', monospace;  
  font-size: 2rem;
  color: maroon;
 }

 h3{
  font-family:'Roboto Mono', monospace;
  font-size: 1.5rem;
  color: darkslategray;
 }

  p{
      font-family:'Roboto Mono', monospace;
      font-size:20px;
      padding-bottom: 100px;
    }
    
 input{
  font-size: 20px;
  text-align: initial;
 }

 form{
  display: inline-block;
 
 }

 button{ font-size: 20px;
    }

 li{
  list-style: none;
 }
   
  
  



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}  */

body {
  font-family: 'Josefin Sans';
  background-color: #FFFAFB;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;

}

::-webkit-scrollbar {
  width: 0px;
}

.right-arrow,
.left-arrow {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF2625;
  font-size: 25px;
  border-radius: 4px;
  position: absolute;
  bottom: -20px;
  right: 80px;
  transform: scale(1, 1);
  transition: 0.3s all ease-in-out;
}

.right-arrow {
  right: 140px;
}

.right-arrow:hover,
.left-arrow:hover {
  transform: scale(1.3, 1.3);
}

.react-horizontal-scrolling-menu--wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.detail-image {
  width: 729px;
  height: 742px;
  margin-top: 60px;
}

.hero-banner-img {
  position: absolute;
  right: 40px;
  top: 0px;
  width: 700px;
  height: 900px;
  margin-top: -330px;

}

.exercise-card {
  width: 400px;
  height: 445px;
  background: #fff;
  border-top: 4px solid #FF2625;
  border-bottom-left-radius: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;
  transform: scale(1, 1);
  transition: 0.3s all ease-in-out;
}

.exercise-card img {
  height: 326px;
}

.bodyPart-card {
  transform: scale(1, 1);
  transition: 0.3s all ease-in-out;
}

.exercise-card:hover,
.bodyPart-card:hover {
  transform: scale(1.1, 1.1);
}

.search-btn:hover {
  color: #FF2625 !important;
  border: 1px solid #FF2625 !important;
}

.exercise-video {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 25%;
  height: 381px;
  text-decoration: none;
  margin-top: -40px;
}

@media screen and (max-width:1366px) {
  .react-horizontal-scrolling-menu--scroll-container {
    width: 800px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: hidden;
    
    
  }

  .left-arrow,
  .right-arrow {
    position: static !important;
  }
  }


@media screen and (max-width:1200px) {
  .detail-image {
    width: 450px;
    height: 350px;
    margin-top: 30px;
    margin-left: 60px;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    width:600px
  }


  .hero-banner-img {
    display: none;
  }

  .exercise-card {
    width: 320px;
  }

  .exercise-video {
    width: 350px;
    height: 350px;
    margin-top: 30px;
    margin: 20px 40px;
  }

}

@media screen and (max-width:900px) {
  .react-horizontal-scrolling-menu--scroll-container {
    width:500px
  }
  
}

@media screen and (max-width:650px) {
  .react-horizontal-scrolling-menu--scroll-container {
    width:400px
  }

  .detail-image {
    width: 350px;
    height: 350px;
    margin-top: 30px;
    margin: 30px;
  }
  
}
  


@media screen and (max-width:400px) {
  .exercise-card {
    width: 280px;
  }
}